import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBuhoRedirectAction } from 'happ-redux/happ-actions/buho/buhoActions';

const useBuho = (initialState) => {
  const [id, setId] = useState(initialState.id);
  const dispatch = useDispatch();
  const { redirectUrl, loading, errorServer, errorMsg } = useSelector((state) => state.buho);

  useEffect(() => {
    if (id) {
      dispatch(getBuhoRedirectAction(id));
    }
  }, [id]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  return {
    loading,
    errorServer,
    errorMsg,
  };
}

export default useBuho;