import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Routes,
    Route,
    Navigate,
    useNavigate
} from 'react-router-dom';
import { Row } from "react-bootstrap";

//Views
import Home from 'happ-views/Home/Home';
import Profile from "happ-views/Profile/Profile";
import ExamHistory from "happ-views/ExamHistory/ExamHistory";
import MedicalAgendaOld from "happ-views/MedicalAgenda/MedicalAgendaOld";
import MedicalAdvice from 'happ-views/MedicalAdvice/MedicalAdvice';
import Login from 'happ-views/Login/Login';
import SuperLogin from 'happ-views/Login/SuperLogin';
import ForgotPassword from 'happ-views/Login/ForgotPassword';
import EmailPassword from 'happ-views/Login/EmailPassword';
import ResetPassword from 'happ-views/Login/ResetPassword';
import TermsAndConditions from 'happ-views/Login/TermsAndConditions';
import MedismartSchedule from 'happ-views/MedismartSchedule/MedismartSchedule';
import MentalHealth from 'happ-views/MentalHealth/MentalHealth';
import CancerSurveys from 'happ-views/CancerSurveys/CancerSurveys';
import MedicalSchedule from 'happ-views/MedicalSchedule/MedicalSchedule';

//components
import NavBar from 'happ-components/NavBar/NavBar';
import NavBarFooter from 'happ-components/NavBarFooter/NavBarFooter';
import MobileNavBar from 'happ-components/MobileNavBar/MobileNavBar';
//actions
import { isLogin } from 'happ-redux/happ-actions/auth/authActions';
import MedicalAgenda from 'happ-views/MedicalAgenda/MedicalAgenda';
import Home3 from 'happ-views/Home/Home3';
import Home4 from 'happ-views/Home/Home4';
import Home3extra from 'happ-views/Home/Home3extra';
import MedicalAgenda2 from 'happ-views/MedicalAgenda/MedicalAgenda2';
import MedicalAgenda3 from 'happ-views/MedicalAgenda/MedicalAgenda3';
import Home3Agendado from 'happ-views/Home/Home3Agendado';
import Home4Agendado from 'happ-views/Home/Home4Agendado';
import HomeExamenesFisicos from 'happ-views/Home/HomeExamenesFisicos';
import Consent from 'happ-views/ConsentView/Consent';
import ConsentConfirmation from 'happ-views/ConsentConfirmation/ConfirmConsent';
import WhatsAppButtonIcon from 'happ-components/WhatsAppButtonIcon/WhatsAppButtonIcon';
import RedirectToBuho from 'happ-views/Buhoredirect/BuhoRedirect';
import SsoAutoLogin from 'happ-views/SsoAutoLogin/SsoAutoLogin';

const RoutesMap = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ProtectedRoute = ({ redirectPath = '/login', children }) => {
        useEffect(() => {
            dispatch(isLogin(navigate));
        }, []);
        return (
            localStorage.getItem("token") !== null ? (
                <Row className="row-nav">
                    <NavBar />
                    <MobileNavBar />
                    {children}
                    <WhatsAppButtonIcon />
                    <NavBarFooter />
                </Row>
            ) : (
                <Navigate to={redirectPath} replace />
            )
        );
    };
    const ProtectedRouteConsent = ({ redirectPath = '/login', children }) => {
        useEffect(() => {
            dispatch(isLogin(navigate));
        }, []);
        return (
            localStorage.getItem("token") !== null ? (
                <Row className="row-nav">
                    {children}
                </Row>
            ) : (
                <Navigate to={redirectPath} replace />
            )
        );
    };
    const RoutesWithoutNavBar = ({ children }) => {
        return children;
    }
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/horaagendada2"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <Home3Agendado />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/horaagendada3"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <Home4Agendado />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/chequeoydiagnostico"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <Home3 />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/examenesdeconfirmacion"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <Home3extra />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/chequeoydiagnosticofinal"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <Home4 />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/examenes-fisicos"
                element={
                    <ProtectedRoute>
                        {/* <Home /> */}
                        <HomeExamenesFisicos />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/mi-viaje"
                element={
                    <ProtectedRoute>
                        <MedicalAgendaOld />
                        {/* <MedicalAgendaCopy /> */}
                    </ProtectedRoute>
                }
            />
            <Route
                path="/agendar-hora"
                element={
                    <ProtectedRoute>
                        {/* <MedicalAgenda /> */}
                        <MedicalSchedule />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/agendar-hora-telemedicina"
                element={
                    <ProtectedRoute>
                        {/* <MedicalAgenda /> */}
                        <MedismartSchedule />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/agendarhora2"
                element={
                    <ProtectedRoute>
                        {/* <MedicalAgenda /> */}
                        <MedicalAgenda2 />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/agendarhora3"
                element={
                    <ProtectedRoute>
                        {/* <MedicalAgenda /> */}
                        <MedicalAgenda3 />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/mi-perfil"
                element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/mis-examenes"
                element={
                    <ProtectedRoute>
                        <ExamHistory />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/salud-mental"
                element={
                    <ProtectedRoute>
                        <MentalHealth />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/prevencion-cancer"
                element={
                    <ProtectedRoute>
                        <CancerSurveys />
                    </ProtectedRoute>
                }
            />

            {/* <Route
                path="/cancer-de-mama"
                element={
                    <ProtectedRoute>
                        <BreastCancerSurvey />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cancer-cervicouterino"
                element={
                    <ProtectedRoute>
                        <CervixCancerSurvey />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cancer-colon"
                element={
                    <ProtectedRoute>
                        <ColonCancerSurvey />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cancer-pulmon"
                element={
                    <ProtectedRoute>
                        <LungCancerSurvey />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cancer-prostata"
                element={
                    <ProtectedRoute>
                        <ProstateCancerSurvey />
                    </ProtectedRoute>
                }
            /> */}
            <Route
                path="/mis-consejos"
                element={
                    <ProtectedRoute>
                        <MedicalAdvice />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <RoutesWithoutNavBar>
                        <Login />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/super-login"
                element={
                    <RoutesWithoutNavBar>
                        <SuperLogin />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/forgot-password"
                element={
                    <RoutesWithoutNavBar>
                        <ForgotPassword />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/email-password"
                element={
                    <RoutesWithoutNavBar>
                        <EmailPassword />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/reset-password/:tokenUrl"
                element={
                    <RoutesWithoutNavBar>
                        <ResetPassword />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/terms-and-conditions"
                element={
                    <RoutesWithoutNavBar>
                        <TermsAndConditions />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/authorize"
                element={
                    <ProtectedRoute>
                        <Consent/>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/confirm-consent"
                element={
                    <ProtectedRouteConsent>
                        <ConsentConfirmation/>
                    </ProtectedRouteConsent>     
                }
            />
            <Route
                path="/buho"
                element={
                    <RoutesWithoutNavBar>
                        <RedirectToBuho />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/sso"
                element={
                    <RoutesWithoutNavBar>
                        <SsoAutoLogin />
                    </RoutesWithoutNavBar>
                }
            />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
    );
}

export default RoutesMap;