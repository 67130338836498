import React from 'react';
import { Image } from 'react-bootstrap';
import './buho.css';
import happLogo from 'assets/imgs/imag_.png';
import buhoLogo from 'assets/imgs/buho-logo.png';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const BuhoRedirection = () => {
    return (
        <div className="redirection-container ">
            <h2 className="redirection-title">Redireccionando a tu cotización con Búho</h2>
            <Image src={happLogo} alt="Company 1" fluid className="company-logo" />
            <DotLottieReact
                src="https://lottie.host/8b81bdb1-2cb1-4fff-9726-7e72d1bb5ee3/hYJ0IN37in.lottie"
                loop
                autoplay
                className="redirection-animation"
            />
            <Image src={buhoLogo} alt="Company 2" fluid className="company-logo" />
        </div>
    );
};

export default BuhoRedirection;