import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import useBuho  from 'happ-custom-hooks/useBuho/useBuho';
import BuhoRedirection from 'happ-components/BuhoRedirect/BuhoRedirection';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';

const BuhoRedirect = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const initialState = {
    loading: false,
    redirectUrl: '',
    errorServer: false,
    id,
  };

  const { loading, errorServer } = useBuho(initialState);

  return (
    
      <Row className="mt-content">
        <HappContainer>
            {loading ? <BuhoRedirection /> : null}
            {errorServer && <p>Ocurrió un problema al redirigirte a búho, por favor intenta nuevamente</p>}
        </HappContainer>
      </Row>
  );
};

export default BuhoRedirect;