import {
  INIT_GET_BUHO_REDIRECT,
  GET_BUHO_REDIRECT,
  ERROR_GET_BUHO_REDIRECT,
} from 'happ-redux/happ-types';

import { getRedirectUrl } from 'happ-redux/happ-services/buho/buhoServices';

export function getBuhoRedirectAction(id) {
  return async (dispatch) => {
    dispatch(initGetBuhoRedirect());
    try {
      const response = await getRedirectUrl(id);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(getBuhoRedirect(response.data.redirectUrl));
        }, 2500);   
      } else {
        dispatch(errorGetBuhoRedirect());
      }
    } catch (error) {
      dispatch(errorGetBuhoRedirect(error.data.msg));
    }
  }
}

const initGetBuhoRedirect = () => ({
  type: INIT_GET_BUHO_REDIRECT,
});

const getBuhoRedirect = (redirectUrl) => ({
  type: GET_BUHO_REDIRECT,
  payload: redirectUrl,
});

const errorGetBuhoRedirect = (errorMsg) => ({
  type: ERROR_GET_BUHO_REDIRECT,
  payload: errorMsg,
});