import axiosClient from 'happ-config/axios';

const getRedirectUrl = async (id) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/buho/get-buho-redirect/${id}`, {headers});
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

export {
    getRedirectUrl
};