import { combineReducers } from 'redux';

// reducers states
import authReducer from './auth/authReducer';
import healthIndicatorsReducer from './healthIndicators/healthIndicatorsReducer';
import examHistoryReducer from './examHistory/examHistoryReducer';
import medicalScheduleReducer from './medicalSchedule/medicalScheduleReducer';
import medicalAdviceReducer from './medicalAdvice/medicalAdviceReducer';
import medicalGoalsReducer from './medicalGoals/medicalGoalsReducer';
import scheduleReducer from './schedule/scheduleReducer';
import medismartScheduleReducer from './medismartSchedule/medismartScheduleReducer';
import oauthReducer from './oauth/oauthReducer';
import mentalHealthReducer from './mentalHealth/mentalHealthReducer';
import cancerJourneyReducer from './cancerJourney/cancerJourneyReducer';
import informedConsentReducer from './informedConsent/informedConsentReducer';
import telehealthProviderReducer from './telehealthProvider/telehealthProviderReducer';
import buhoReducer from './buho/buhoReducer';

export default combineReducers({
    auth: authReducer,
    indicators: healthIndicatorsReducer,
    exams: examHistoryReducer,
    schedule: scheduleReducer,
    advices: medicalAdviceReducer,
    goals: medicalGoalsReducer,
    medismart: medismartScheduleReducer,
    oauth: oauthReducer,
    mentalHealth: mentalHealthReducer,
    cancer: cancerJourneyReducer,
    medicalSchedule: medicalScheduleReducer,
    informedConsent: informedConsentReducer,
    telehealthProvider: telehealthProviderReducer,
    buho: buhoReducer,
});