import React, {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import LoaderView from 'happ-components/Loader/LoaderView';
const SsoAutoLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if ( location.pathname === '/sso/' && location.search !== '' ) {
            const { search } = location;
            const token = search.split('token=', 2)[1];
            if (token !== '') {
                localStorage.setItem('token', token);
                navigate('/confirm-consent');
            }
        }
    }, [location]);

    return (
        <Row className="mt-content ">
            <HappContainer>
                <>
                    <div className='text-center mt-5 mb-3'>
                        <LoaderView
                            animation="border"
                        />
                        <p>Redireccionando a Happ...</p>
                    </div>
                </>
            </HappContainer>
        </Row>      
    );
}
 
export default SsoAutoLogin;