import {
  INIT_GET_BUHO_REDIRECT,
  GET_BUHO_REDIRECT,
  ERROR_GET_BUHO_REDIRECT,
} from 'happ-redux/happ-types';

const initialState = {
  loading: false,
  redirectUrl: null,
  errorServer: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INIT_GET_BUHO_REDIRECT:
      return {
        ...state,
        loading: true,
      };
    case GET_BUHO_REDIRECT:
      return {
        ...state,
        loading: false,
        errorServer: false,
        redirectUrl: action.payload,
      };
    case ERROR_GET_BUHO_REDIRECT:
      return {
        ...state,
        loading: false,
        errorServer: true,
      };
    default:
      return state;
  }
}